import { render, staticRenderFns } from "./CuratedProjectCard.vue?vue&type=template&id=9d931456&scoped=true&"
import script from "./CuratedProjectCard.vue?vue&type=script&lang=js&"
export * from "./CuratedProjectCard.vue?vue&type=script&lang=js&"
import style0 from "./CuratedProjectCard.vue?vue&type=style&index=0&id=9d931456&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d931456",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ForgeImage: require('/tmp/build_3ab6882f/components/ForgeImage.vue').default,FeaturedCountdown: require('/tmp/build_3ab6882f/components/FeaturedCountdown.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAvatar,VCard,VCardSubtitle,VCardText,VChip,VCol,VDivider,VLayout,VRow,VSheet})
